import React, { useState, useEffect } from "react";
export default function NavigationPopUp({ orderData, closeCallback }) {
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, []);
  return (
    <div className="w-[100vw] h-[90vh] max-w-[720px] bg-black bg-opacity-20 absolute z-10 cb-pop-up">
      <div className=" w-10/12 m-auto p-3 mt-[20vh] bg-white rounded-md text-center">
        <div className="flex justify-between">
          <div></div>
          <div className="mt-2 text-2xl font-bold">{`#${orderData.orderNumber}`}</div>
          <span className="float:right text-2xl" onClick={closeCallback}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
              />
              <path
                fillRule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
              />
            </svg>
          </span>
        </div>

        <div className="mt-2 text-2xl text-[000A26]">
          {`${orderData.location.street} ${orderData.location.house} ${orderData.location.city} ${orderData.location.state} ${orderData.location.pincode}`}
        </div>

        <button
          className="rounded-md bg-[#88C9BB] tracking-widest font-semibold text-2xl text-white p-3 w-10/12 mt-10 mb-2"
          onClick={() =>
            window.open(
              `https://www.google.com/maps/dir/${latitude},${longitude}/${orderData.location.street} ${orderData.location.house} ${orderData.location.city} ${orderData.location.state} ${orderData.location.pincode}`
            )
          }
        >
          NAVIGATE
        </button>
      </div>
    </div>
  );
}
