import React, { useEffect, useState } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import ApiRequest from "../utils/ApiRequest";
import getCenter from "geolib/es/getCenter";
import MapMarker from "./MapMarker";
import NavigationPopUp from "./NavigationPopUp";

function Map() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const coordinates = data.map((data) => ({
    latitude: data.location.lat,
    longitude: data.location.long,
  }));
  const [showPopUp, setShowPopUp] = useState(false);
  const center = getCenter(coordinates);
  const [orderData, setOrderData] = useState({});
  const [viewport, setViewport] = React.useState({
    latitude: center.latitude,
    longitude: center.longitude,
    zoom: 5,
    width: "100%",
    height: "90vh",
  });

  useEffect(() => {
    ApiRequest.get("driver/getSchedule").then((res) => {
      if (res.status === "Success") {
        setData(res.data);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    setViewport({
      latitude: center.latitude,
      longitude: center.longitude,
      zoom: 5,
      width: "100%",
      height: "90vh",
    });
  }, [data]);
  return (
    <>
      {showPopUp && (
        <NavigationPopUp
          orderData={orderData}
          closeCallback={() => setShowPopUp(false)}
        />
      )}
      
      {!loading && (
        <ReactMapGL
          mapStyle="mapbox://styles/akshaysahani/cl1el183c00jy14qoau5tmq64"
          mapboxApiAccessToken="pk.eyJ1IjoiYWtzaGF5c2FoYW5pIiwiYSI6ImNsMTUydm15cjBvZzQzbHYwaTlheTgwN3YifQ.h9Q5O2kB8r2fYNE90p_Itg"
          {...viewport}
          onViewportChange={(viewport) => setViewport(viewport)}
        >
          {data
            .filter((m) => m.location.long && m.location.lat)
            .map((data, index) => (
              <div key={index}>
                <Marker
                  className="w-100"
                  longitude={parseFloat(data.location.long)}
                  latitude={parseFloat(data.location.lat)}
                  offsetLeft={-20}
                  offsetTop={-30}
                  onClick={() => {
                    setOrderData(data);
                    setShowPopUp(true);
                  }}
                >
                  <MapMarker value={index + 1} />
                </Marker>
              </div>
            ))}
        </ReactMapGL>
      )}
    </>
  );
}

export default Map;
