import ApiRequest from "../../utils/ApiRequest";
import StorageService from "../../utils/StorageService";
import {
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAIL,
  GET_COMPLETED
} from "../constant/driverConstant";

export const getScheduleAction = (searchQuery, isCompleted = '') => async (dispatch) => {
  try {
    // dispatch({
    //   type: GET_SCHEDULE_REQUEST,
    // });
    const res = await ApiRequest.get(
      `driver/getSchedule${searchQuery ? `?search=${searchQuery}` : ""}`
    );
    const data = {
      res: res,
      isCompleted: isCompleted
    }
    dispatch({
      type: GET_SCHEDULE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SCHEDULE_FAIL,
      payload: error,
    });
  }
};
export const isOrderCompleted = (isCompleted) => async (dispatch) => {
  dispatch({
    type: GET_COMPLETED,
    payload: isCompleted,
  });
}
