import {
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAIL,
  GET_COMPLETED,
} from "../constant/driverConstant";

export const getScheduleReducer = (
  state = { get_schedule_responce: [], loading: true , isCompleted : false },
  action
) => {
  switch (action.type) {
    case GET_SCHEDULE_REQUEST:
      return { loading: true };

    case GET_SCHEDULE_SUCCESS:
      console.log("reducer: ", action.payload.res);
      return { loading: false, get_schedule_responce: action.payload.res, isCompleted: action.payload.isCompleted };

    case GET_SCHEDULE_FAIL:
      return { loading: false, error: action.payload };
    /**Completed  */
    case GET_COMPLETED:
      return { ...state, isCompleted: action.payload };

    default:
      return state;
  }
};
