function PickAndDropModal({ children, open, width }) {
  return (
    <>
      {open && (
        <div className=" absolute flex items-center justify-center top-0 bottom-0 right-0 left-0 bg-gray-900/40 cb-pop-up cb-z-index">
          <div
            className={` bg-white ${width} opacity-100 shadow-md z-10 rounded-md px-3 py-3`}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
}

export default PickAndDropModal;
