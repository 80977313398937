import React from "react";
import ScheduleHeader from "../components/ScheduleHeader";
import { Link } from "react-router-dom";
import PickUpCard from "../components/PickUpCard";
import DropCard from "../components/DropCard";
import { useSelector } from "react-redux";
import { useState } from "react";
function SingleOrder({ location }) {
  // const orderDetails = location.state.route;
  let [sameRecord, increaseCount] = useState(0);
  let [orderAccepted, setorderAccepted] = useState(false);
  const { loading, get_schedule_responce, isCompleted } = useSelector(
    (state) => state.getScheduleReducer
  );
  const orderDetails = get_schedule_responce.data[location];
  const {
    orderNumber,
    services,
    deliveryNotes,
    _id,
    customer,
    location: address,
    deliveryPreference,
  } = orderDetails;
  console.log("callll", orderDetails);
  let pickUpList = services.filter(
    (p) =>
      p.status === "pending" ||
      p.status === "processing" ||
      p.status === "processed"
  );
  const record1 = pickUpList.filter(
    (p) => p.name === "Dry-Cleaning" || p.name === "Laundered Shirts"
  );
  const record2 = pickUpList.filter(
    (p) => {
      if (p.name === "Dry-Cleaning") {
        p['same'] = false
        p['show'] = false
        return
      } else if (p.name === "Laundered Shirts") {
        p['same'] = false
        p['show'] = false
        return
      }
      else {
        p['same'] = false
        p['show'] = false
        return p
      }
    }
  );
  if (record1.length > 1) {
    record1.map((obj, i) => {
      if (i == 1)
        obj['show'] = true
      else
        obj['show'] = false
      obj['same'] = true;
    }
    )
  }
  pickUpList = Array.from(new Set(record2.concat(record1)));
  let dropList = services.filter(
    (p) => p.status === "readyToDeliver" || p.status === "delivered"
  );
  // droplist
  const record3 = dropList.filter(
    (p) => p.name === "Dry-Cleaning" || p.name === "Laundered Shirts"
  );
  const record4 = dropList.filter(
    (p) => {
      if (p.name === "Dry-Cleaning") {
        p['same'] = false
        p['show'] = false
        return
      } else if (p.name === "Laundered Shirts") {
        p['same'] = false
        p['show'] = false
        return
      }
      else {
        p['same'] = false
        p['show'] = false
        return p
      }
    }
  );
  if (record3.length > 1) {
    record3.map((obj, i) => {
      if (i == 1)
        obj['show'] = true
      else
        obj['show'] = false
      obj['same'] = true;
    }
    )
  }
  dropList = Array.from(new Set(record4.concat(record3)));
  return (
    <div className="py-5">
      {/* <ScheduleHeader /> */}
      {/* <div>
          <button type="button"  className="primary-btn" style={{
            float:"right",marginRight:"25px",
             fontSize:"16px" }} >Accept Order</button>
      </div> */}
      <div className="py-2 px-6 flex bg-white">
        <div>
          {/* <Link to="/schedule">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 text-[#C1C7D0] cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </Link> */}
        </div>
        <div className=" flex-grow font-medium text-[#000A26] text-[20px]">
          #{orderNumber}
        </div>
        <div style={{ display:(orderAccepted?"flex":"none") ,alignItems:"center"}} >
          {/* <a href="sms:1234567890;?&body=message">Text Message</a> */}
          <button type="button" className="primary-btn" style={{ marginRight:"5px"}}>
            <a
              href={`sms:${customer.phone};?&body=Your NimNim driver is on the way!
                                                  Please text back if you have any issues.`}
            >
             O
            </a>
          </button>
          <button type="button" className="primary-btn" style={{ marginRight:"5px"}}>
            <a
              href={`sms:${customer.phone};?&body=NimNim has arrived for your order!! Thank you`}
            >
              A
            </a>
          </button>
          <button type="button" className="primary-btn"  >
            <a
              href={`tel:${customer.phone}`}
            >
             {/* <img className="img-fluid" src="/call.svg" alt="" srcset="" style={{ WebkitFilter : "brightness(0) invert(1)" , filter : "brightness(0) invert(1)" , height:"20px" , width:"20px" , zIndex:"10" , position: "relative" }} />
               */}
               C
            </a>
          </button>
        </div>
      </div>
      <div>
          <button type="button"
           className={orderAccepted? "text-[#41E024] flex justify-center rounded-md font-medium items-center px-2 py-1 bg-[#41E024]/20": "primary-btn"}
           style={{   float:"right",marginRight:"25px", fontSize:"16px" }}
            onClick={()=>{setorderAccepted(true)}} >
               {orderAccepted? "Accepted":"Accept Order"}
              </button>
      </div>
      <div className=" bg-white mx-6 mt-4 py-2 rounded-md ">
        <h3 className=" text-[16px] text-[#000A26]">
          {`${customer.first_name} ${customer.last_name}`}
        </h3>
        <h4 className="text-[16px] text-[#95CFC6]">
          {deliveryPreference
            ? deliveryPreference.toLowerCase().includes("person")
              ? "In Person"
              : deliveryPreference.toLowerCase().includes("concierge")
                ? "Concierge"
                : "Foyer"
            : "Not Specified"}
        </h4>
        <p className="text-[14px] mt-1 text-[#000A26] opacity-40 w-9/12">
          {Object.values(address).some((e) => e !== "")
            ? `${address.house} ${address.street} ${address.area} ${address.city} ${address.state} ${address.pincode}`
            : "Not specified"}
        </p>
        <h4 className="text-[16px] text-[#95CFC6]">Note*</h4>
        <p className="text-[14px] mt-1 text-[#000A26] opacity-40 w-9/12">
          {deliveryNotes ? deliveryNotes : "Not specified"}
        </p>
        {
          pickUpList.length > 0 && (
            <>
              <div className="text-[16px] text-[#95CFC6] my-3">
                Pickups • {pickUpList.length} Services
              </div>
              {pickUpList.map((item, i) => {
                return (
                  <>
                    <PickUpCard key={`key-${i}`} item={item} id={_id} orderAccepted ={orderAccepted} />
                    {
                      pickUpList.length > 1 && i !== pickUpList.length - 1 && !item.same && (
                        <hr className="my-2" />
                      )
                    }
                  </>
                );
              })}
            </>
          )
        }
        <br />
        {
          dropList.length > 0 && (
            <>
              <div className="text-[16px] text-[#95CFC6] my-3">
                Drop • {dropList.length} Services
              </div>
              {dropList.map((item, i) => {
                return (
                  <>
                    <DropCard key={`key-${i}`} item={item} id={_id} orderAccepted ={orderAccepted} />
                    {dropList.length > 1 && i !== dropList.length - 1 && !item.same && (
                      <hr className="my-2" />
                    )}
                  </>
                );
              })}
            </>
          )
        }
      </div>
    </div>
  );
}
export default SingleOrder;