import React, { useEffect, useState } from "react";
import moment from "moment";
import ApiRequest from "../utils/ApiRequest";
import StorageService from "../utils/StorageService";
import { useHistory } from "react-router-dom";
import NimNimLoader from "../components/NimNimLoader";

export default function CheckIn() {
  const [state, setState] = useState({ driver: {}, isLoading: true });
  const [routeDetails, setRouteDetails] = useState({ pickups: 0, drops: 0 });
  const [menu, setMenu] = useState(false);
  const history = useHistory();
  useEffect(() => {
    ApiRequest.get("driver/getme").then((res) => {
      setState({ driver: res.data, isLoading: false });
      ApiRequest.get("driver/getDuties").then((res) => {
        if (!res.error) {
          setRouteDetails(res.data);
        }
      });
    });
  }, []);
  const { driver, isLoading } = state;
  const { pickups, drops } = routeDetails;
  const checkIn = () => {
    ApiRequest.get(`driver/check-in/${driver._id}`).then(() =>
      history.push("/schedule")
    );
  };
  return (
    <div className="relative">
      {isLoading ? (
        <div>
          <NimNimLoader />
        </div>
      ) : (
        <>
          <div className="bg-black h-[60vh] bg-gradient-to-b from-[#3477A4] to-[#92D5C6] ">
            <div className="flex justify-end pt-2 pr-2 items-center relative ">
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-7 w-7 text-white cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  onClick={() => setMenu(!menu)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                  />
                </svg>
              </button>
              <div
                className={` h-3 w-3 ${
                  menu ? "visible" : "invisible"
                } bg-white z-10 absolute right-[16px] top-[32px] `}
                style={{ clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)" }}
              />
              <div
                className={`absolute ${
                  menu ? "visible" : "invisible"
                } flex justify-center flex-col z-10 items-center  top-[43px] right-[7px] bg-white shadow-md rounded-md py-3 space-y-2 px-3`}
              >
                <div className=" flex items-center space-x-1 cursor-pointer text-[17px] font-medium justify-center whitespace-nowrap text-[#000A26]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                  </svg>
                  <span
                    onClick={() => {
                      StorageService.remove("token");
                      history.push("/");
                    }}
                  >
                    Log out
                  </span>
                </div>
              </div>
            </div>
            <div className="text-white p-[5vh] text-[3vh]">
              {`Hello! ${driver.name},`}
              <br />
              Today
              <br />
              <span className="opacity-[0.6] text-[2vh]">{`${moment().format(
                "dddd, MMMM D,YYYY"
              )}`}</span>
            </div>
          </div>
          <div
            className="bg-gradient-to-b from-[#92D5C6] to-[#92D5C6] h-[10vh] z-0"
            style={{ clipPath: "ellipse(50% 60% at 50% 1%)" }}
          />

          <div className="ml-[5%] mt-[40vh] w-[90%] h-[40vh] rounded-lg top-20 bg-white z-10 absolute bottom-0 text-center text-[#000A26] text-[130%]">
            <div className="mt-[-12%] m-auto w-3/12 h-0 pb-[25%] rounded-full bg-[#60A3B4]">
              <h1 className="text-[50px] sm:text-[89px] md:text-[92px] pt-3 text-white">
                {driver.name.split(" ").length > 1
                  ? driver.name.split(" ")[0].toUpperCase()[0]
                  : driver.name.toUpperCase()[0]}
              </h1>
            </div>
            <div className="w-8/12 m-auto mt-3">
              Lets start the shift.
              <br /> Your schedule has:
              <br />{" "}
              <span className=" font-bold">
                {pickups} pickups & {drops} drops
              </span>{" "}
              <br />
              Start Shift Now
            </div>
            <button
              className="mt-[10%] w-[30%] bg-[#88C9BB] py-3 m-auto rounded-lg text-white"
              onClick={() => checkIn()}
            >
              CHECK IN
            </button>
          </div>
        </>
      )}
    </div>
  );
}
