import React, { useState, useEffect } from "react";
import PickAndDropModal from "./PickAndDropModal";
import UploadFile from "../utils/UploadFile";
import UploadFile2 from "../utils/UploadFile2";

import ApiRequest from "../utils/ApiRequest";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";
import { useDispatch } from "react-redux";
import { getScheduleAction } from "../redux/actions/driverAction";

function DropCard({ item, id, orderAccepted,pdate = '', props }) {
  const isDeliveredCheck = item.status === "readyToDeliver" ? false : true;
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [isDelivered, setIsDelivered] = useState(isDeliveredCheck);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedImage2, setUploadedImage2] = useState(null);
  const [uploadedImage3, setUploadedImage3] = useState(null);
  const { dropOffSlot, status, pickupSlot } = item;
  const [confirmcheckbox, setconfirmcheckbox] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (uploadedImage) {
      setOpen(true);
      // setConfirmOpen(true);
    }
  }, [uploadedImage]);

  const upload = (data, successCallback) => {
    return ApiRequest.multipart("upload", "POST", data).then((response) => {
      if (response.error || response.errors) {
      } else {
        setUploadedImage(response.path)
        if (successCallback) {
          successCallback(response);
        }
      }
    });
  };


  const onDrop1 = (acceptedFiles) => {
    upload(prepareFormData(acceptedFiles), (response) =>
      // this.props.upload(response)
      setUploadedImage(response.path)
    );
  };
  const prepareFormData = (value) => {
    let formData = new FormData();
    formData.append("image", value);
    return formData;
  };

  const {
    getRootProps,
    getInputProps,
    isdragactive,
    isdragaccept,
    isdragreject,

  } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles) => {
      const imageFile = acceptedFiles[0];
      const options = {
        maxSizeMB: 0.01,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(imageFile, options);
        onDrop1(compressedFile);
        setOpen(true);

      } catch (error) {
        console.log(error);
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(files, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },

  });

  const getCoords = async () => {
    return new Promise( (resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
          position => resolve(position),
          error => reject(error)
      )
  })
};

  const pickUpHandler = async() => {
    console.log("clickeddd");

   let cordinatesupdated= false;
   let lat ,lon;
  try {
    const position = await getCoords();
     lat = position.coords.latitude;
     lon = position.coords.longitude;
    cordinatesupdated = true;
} catch(err) {
    console.log("error",err);
    alert("Allow Location for pick/drop off first ");
}

   const d = new Date();
   let driverDropOffTime = d.toLocaleString("en-US",{ timeZone: "America/New_york" });
   let driverDropOffLocation = lat + " , " + lon;
   console.log(driverDropOffTime , driverDropOffLocation);

   if(cordinatesupdated){
    let dropImages= [];

    if(uploadedImage){
      dropImages.push(uploadedImage)
    }
    if(uploadedImage2){
      dropImages.push(uploadedImage2)
    }
    if(uploadedImage3){
      dropImages.push(uploadedImage3)
    }
    setOpen(false);
    const data = {
      service_id: item._id,
      order_id: id,
      images: dropImages,
      status: "delivered",
      driverDropOffTime , driverDropOffLocation
    };
    ApiRequest.post("driver/updateOrderStatus", data).then((res) => {
      if (res.isSuccess) {
        setIsDelivered(true);
        dispatch(getScheduleAction());
      }
    });
  }
  };
  const calculateItems =(item)=>{
    let total=0;
      for(let task of item.tasks){
         total = total + task.items.length;
      }
      return total;
  }
  const calculateItems2 =(item)=>{
    let total=0; let serviceitems="";
    let count1=0, count2=0;

      if(item.alias =="household-items"){
        for(let i of item.items){
          total += i.qty;
          if(i.if_dryCleaned){count1++;}
          if(i.if_laundered){count2++;}
        }
      }

      if(item.alias=="dry-cleaning"){
        for(let i of item.items){
          if(i.female_count){ total += i.female_count; }
          if(i.male_count){ total += i.male_count; }
       }
      }

      if(count1 >0 && count2 >0){
        serviceitems = " , ServiceTypes : Both(laundered & drcleaning)"
      }else if(count1>0){
        serviceitems = " , ServiceType : drcleaning"
      }else if(count2>0){
        serviceitems =" , ServiceType : laundered"
      }
      return total+ serviceitems;
  }
  return (
    <>
      <div className="flex items-center justify-between">
        <div className=" text-[#000A26] font-bold text=[14px]">
          #{item.serviceNumber}
        </div>
        <PickAndDropModal open={open} width={"w-3/4"}>
          <div className=" w-full">
            <div className=" flex justify-end ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7 text-[#000A26] opacity-70 cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
                onClick={() => setOpen(false)}
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className=" text-center">
              <h4 className="text-[#000A26] text-[21px] mt-7  font-bold">
                #{item.serviceNumber}
              </h4>
              <h4 className="text-[#88C9BB] text-[18px] my-3 font-medium">
                {item.name}
              </h4>
              {/* <h3 className="text-[#000A26] text-[16px] font-medium">
                {uploadedImage ? "Delivered to customer" : "Out for delivery"}
              </h3> */}
              {!uploadedImage && (
                <h5 className=" text-[#000A26] text-[14px] opacity-50 font-medium ">
                  Customer
                </h5>
              )}

              <div className="flex justify-center items-center my-4">
              <UploadFile2
                  onUploaded={(response) => {
                    setUploadedImage(response.path)
                  }}
                  onRemoved={() => {
                    setUploadedImage(null);
                  }}
                initialPath={uploadedImage}
                 />

                <UploadFile2
                  onUploaded={(response) => {
                    setUploadedImage2(response.path)
                  }}
                  onRemoved={() => {
                    setUploadedImage2(null);
                  }}
                initialPath={uploadedImage2}
                 />

                 <UploadFile2
                  onUploaded={(response) => {
                    setUploadedImage3(response.path)
                  }}
                  onRemoved={() => {
                    setUploadedImage3(null);
                  }}
                initialPath={uploadedImage3}
                 />
              </div>
              <div>
                <h2 className="cb-color-pickup mt-2">
                  Before droppingoff
                </h2>
                <p className="px-[21px] cb-color-pickup mb-4">
                  Ensure that bag has the name tag of the customer, If not make sure
                  to add a name tag!
                </p>
              </div>
              <button
                onClick={() => pickUpHandler()}
                disabled={(uploadedImage || uploadedImage2 || uploadedImage3 ) ? false : true}
                className=" w-[80%] mx-4 mt-3 primary-btn py-2 text-[18px] disabled:opacity-50"
              >
                {(uploadedImage || uploadedImage2 || uploadedImage3 )  ? "CONFIRM DROPOFF" : "DELIVER"}
              </button>
              {!uploadedImage && (
                <h4 className=" text-[#000A26] text-[14px] font-medium opacity-50 mt-2">
                  Add photo to update status
                </h4>
              )}
            </div>
          </div>
        </PickAndDropModal>
        <PickAndDropModal open={confirmOpen} width={"w-[50%]"}>
          <div className=" text-center pb-3">
            <h3 className="text-[18px] text-[#000A26] font-bold mt-4">
              Delivery Advisory
            </h3>
            <h2 className="text-[16px] text-[#000A26] font-medium mt-2">
              Before droppingoff
            </h2>
            <p className=" lowercase px-[21px] text-[16px] text-[#000A26] font-medium mb-4">
              ensure that bag has the name tag of the customer, If not make sure
              to add a name tag!
            </p>

            <button
              onClick={() => {
                setOpen(true);
                setConfirmOpen(false);
              }}
              className="mx-4 mt-3 primary-btn py-1 px-6 font-medium text-[17px]"
            >
              CONFIRM
            </button>
          </div>
        </PickAndDropModal>
        {
          item.show ? '' : <> {isDelivered ? (
            <div className=" text-[#FCA541] bg-light-yellow px-3 py-0.5 rounded-md">
              DELIVERED
            </div>
          ) : (
            <button className="primary-btn"
            style={confirmcheckbox ? {opacity: 1} : {opacity : 0.4}}
            disabled={confirmcheckbox ? "" : "true"}
            onClick={() => setOpen(true)}
              {...getRootProps({ isdragactive, isdragaccept, isdragreject })}>
              <input {...getInputProps()} accept="image/*;capture=camera"
              />
              DROP
            </button>
          )}
          </>
        }
      </div>
      <h3 className=" text-[#000A26]  opacity-50 text=[14px] font-medium cb-text-justify">
        <span>{item.name} </span> <div className="text-[14px] text-[#000A26] mt-1 cb-font-weight">{ moment(item.dropOffDate).utc().format('MM/DD/YYYY') } </div>
      </h3>
      <div className=" flex justify-between text-[14px] mt-1 text-[#000A26] opacity-40">
        <div>{item.status}</div>
        <div>
          {status === "pending" || status === "readyToDeliver"
            ? `${status === "pending"
              ? `${pickupSlot ? pickupSlot : "Not specified"}`
              : ""
            }  ${status === "readyToDeliver"
              ? `${dropOffSlot ? dropOffSlot : "Not specified"}`
              : ""
            }`
            : "Not specified"}
        </div>
      </div>
      <div className= {(orderAccepted === true ? "text-[14px] mt-1 text-[#000A26] opacity-80" : "text-[14px] mt-1 text-[#000A26] opacity-20")} >
      <input type="checkbox" disabled={(orderAccepted === true ? false : true)} onChange={(event)=>{ setconfirmcheckbox(event.target.checked) ; }} />
        {item.noOfClothes != undefined ?
          <span> No of Items : {item.noOfClothes}</span>
         : ""
         }

         {item.alias === "tailoring" || item.alias == "shoe-repair"
         ? <span> No of Items : {calculateItems(item)}</span>
          : ""
          }

         {item.alias === "household-items" || item.alias == "dry-cleaning"
         ? <span> No of Items : {calculateItems2(item)}
            {/* {item.alias === "household-items" ? <div>Service Items : </div> : ""} */}
          </span>
          : ""
          }

         {console.log("item",item.alias, "items", item.noOfClothes)}
      </div>


      <div className=" text-[14px] mt-1 text-[#000A26] opacity-40">
        {item.alias != "tailoring" && item.alias !="shoe-repair"?
          <div>Special notes : {item.special_notes !="" ? item.special_notes : "Not specified"}</div>
         : <div>Special notes : {item.tasks.length>=1 ? item.tasks[0].special_notes : "Not specified"}</div>
         }
      </div>
    </>
  );
}

export default DropCard;