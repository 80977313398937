import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';


const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div`
  padding: 10px 0;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  width: 75px;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
`;

const Para = styled.p`
    margin-bottom: 0;
    text-align: center;
    font-size: 12px;
`;


const FileUpload2 = (props) => {
    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps,isDragActive, isDragAccept,
        isDragReject} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            if(props.onDrop){
                props.onDrop(acceptedFiles);
            }
        }
    });


    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);
        
    return (
            <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                <input {...getInputProps()} />
                <Para>Drag & drop, or Click</Para>
            </Container>
    );
}

export default FileUpload2;