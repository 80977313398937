import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginScreen from "./screen/LoginScreen";
import CheckIn from "./screen/CheckIn";
import Schedule from "./screen/Schedule";
import SingleOrder from "./screen/SingleOrder";
import 'font-awesome/css/font-awesome.min.css';

import PrivateRoute from "./utils/PrivateRoute";

function App() {
  return (
    <Router>
      <main className="max-w-[720px] h-[100vh] relative m-auto bg-[#F9F9FF]">
        <Switch>
          <Route exact path="/" component={LoginScreen} />
          <PrivateRoute path="/check-in" component={CheckIn} />
          <PrivateRoute exact path="/schedule" component={Schedule} />
          <PrivateRoute
            exact
            path="/schedule/:orderNum"
            component={SingleOrder}
          />
        </Switch>
      </main>
    </Router>
  );
}

export default App;
