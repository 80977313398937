import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import ApiRequest from "../utils/ApiRequest";
import StorageService from "../utils/StorageService";
import { getScheduleAction, isOrderCompleted } from "../../src/redux/actions/driverAction";
import { useDispatch } from "react-redux";

// function ScheduleHeader(props) {
   function ScheduleHeader({ toggle }) {
  const [menu, setMenu] = useState(false);
  const [menuCompleted, setMenuCompleted] = useState(false);
  // const [completed, setCompletd] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchToggle, setSearchToggle] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // console.log("props >>>", props);
  const checkout = () => {
    history.push("/check-in");
    checkoutCall();
  };
  const setCompletedFun = (completed) => {
    dispatch(isOrderCompleted(completed))
    setMenuCompleted(false);
  }
  const checkoutCall = () => {
    ApiRequest.get("driver/getme").then((res) => {
      ApiRequest.get(`driver/check-out/${res.data._id}`)
        .then((res) => res)
        .catch((error) => console.log(error));
    });
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSearchQuery(e.target.value);
      dispatch(getScheduleAction(searchQuery));
      history.push("/schedule");
    }
  };

  return (
    <>
      <div className="flex items-center  text-white space-x-3 bg-gradient-to-r from-[#3679A4] to-[#8DD0C4] py-4 px-6">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-7 w-7 cursor-pointer "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() => setMenuCompleted(!menuCompleted)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
          <div
            className={` h-3 w-3 ${menuCompleted ? "visible" : "invisible"
              } bg-white z-10 absolute cb-hide-completed-icon `}
            style={{ clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)" }}
          />
          <div
            className={`absolute ${menuCompleted ? "visible" : "invisible"
              } flex justify-center flex-col z-10 items-center bg-white cb-hide-completed shadow-md rounded-md py-3 space-y-2 px-3`}
          >
            <div
              onClick={() => setCompletedFun(false)}
              className=" text-[#000A26] whitespace-nowrap text-[17px] font-medium cursor-pointer"
            >
              Show Completed
            </div>
            <div className=" flex items-center space-x-1 cursor-pointer text-[17px] font-medium justify-center whitespace-nowrap text-[#000A26]">
              <span
                onClick={() => { setCompletedFun(true) }}
              >
                Hide Completed
              </span>
            </div>
          </div>
        </div>

        <div className="font-medium flex-grow text-[24px]">
          <Link to="/schedule">Schedule</Link>
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-7 w-7 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() => setSearchToggle(!searchToggle)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        {/* <div className="h-7 w-7 cursor-pointer">
          <img src="./qr-code-scan-icon.svg" onClick={()=>props.parentFunction()}/>
        </div> */}
        
        <div className="flex items-center relative ">
          <button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-7 w-7 cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={() => setMenu(!menu)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </button>
          <div
            className={` h-3 w-3 ${menu ? "visible" : "invisible"
              } bg-white z-10 absolute right-[9px] top-[28px] `}
            style={{ clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)" }}
          />
          <div
            className={`absolute ${menu ? "visible" : "invisible"
              } flex justify-center flex-col z-10 items-center  top-[40px] right-[2px] bg-white shadow-md rounded-md py-3 space-y-2 px-3`}
          >
            <div
              onClick={() => checkout()}
              className=" text-[#000A26] whitespace-nowrap text-[17px] font-medium cursor-pointer"
            >
              Check out
            </div>
            <div className=" flex items-center space-x-1 cursor-pointer text-[17px] font-medium justify-center whitespace-nowrap text-[#000A26]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              <span
                onClick={() => {
                  checkoutCall();
                  StorageService.remove("token");
                  history.push("/");
                }}
              >
                Log out
              </span>
            </div>
          </div>
        </div>
      </div>
      {searchToggle && (
        <div className="w-full bg-white py-3 ">
          <div className=" flex items-center justify-between border-[3px] py-1 px-2 rounded-md  border-[#000A26] space-x-2 w-[70%] m-auto">
            <input
              type="text"
              className=" w-[90%] focus:outline-none bg-transparent text-[18px] font-medium"
              value={searchQuery}
              onKeyPress={(e) => handleKeyPress(e)}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={() => {
                history.push("/schedule");
                dispatch(getScheduleAction(searchQuery));
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>
      )}
    </>
  );
}

export default ScheduleHeader;
