import React from 'react'
import ApiRequest from './ApiRequest'
import imageCompression from "browser-image-compression";

import FileUpload2 from './FileUpload2'
// import {Spinner} from 'reactstrap'
// import { spawn } from 'child_process';

export default class UploadFile2 extends React.Component{

    state = {
        image: null,
        isUploading: false
    }

    constructor(props){
        super(props);
        this.state.image = this.props.initialPath;
    }

    componentWillReceiveProps(nextProps){
        this.setState({image: nextProps.initialPath});
    }

    upload = (data, successCallback) => {
        this.setState({isUploading: true})
        return ApiRequest.multipart('upload', 'POST', data)
                .then((response) => {
                    this.setState({isUploading: false})
                    if(response.error || response.errors)
                    {
                        
                    }else{
                        this.setState({image: response.path});
                        if(successCallback)
                        { 
                            successCallback(response)
                        }
                    }
                })
    };

    prepareFormData(value){
        let formData = new FormData();
        formData.append('image', value);
        return formData;
        
    }

    onDrop = async(acceptedFiles) => {
        // console.log("dropp", acceptedFiles[0]);
        const imageFile = acceptedFiles[0];
        const options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);
        //   console.log(compressedFile);
          this.upload(
            this.prepareFormData(compressedFile)
            , 
            (response) => {if(this.props.onUploaded){
                this.props.onUploaded(response)
            }else{
                this.props.upload(response)
            }
        }
        );
        } catch (error) {
          console.log(error);
        }
       
    }

    onRemoved = () => {
       console.log("removed");
        this.setState({ image: null})
        if(this.props.onRemoved){
            this.props.onRemoved();
        }
    }

    render(){
        let imageUrl = this.state.image;
        return (
            <div className={''} style={{ margin: '5px' }}>
                {this.state.image ? null : <FileUpload2 onDrop={this.onDrop} />}
                {/* {this.state.isUploading && <div><Spinner type="grow" color="primary" style={{ width: '1.5rem', height: '1.5rem' }} /></div>} */}
                {this.state.image &&                 
                    <div className="position-relative mx-auto" style={{backgroundPosition: 'center center', backgroundImage: `url(${imageUrl})`, 
                    backgroundColor: '#eeeeee',backgroundRepeat: 'no-repeat',backgroundSize: 'contain', height:'100px' ,width:'100px'}}>
                       <div style={{
                        position: 'relative'

                       }}>
                        <span  style={{position: 'absolute', top: '-10px', right: '-6px' , color:"red" , cursor :"pointer"}}>
                            <i className="fa fa-window-close text-danger" onClick={this.onRemoved} aria-hidden="true"></i>
                        </span>
                       </div>
                    </div>
                }
            </div>
        )
    }
}