import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ApiRequest from "../../src/utils/ApiRequest";
import Logo from "../assets/img/logo.png";
import StorageService from "../utils/StorageService";

function LoginScreen() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const loginUser = () => {
    setLoading(true);

    if (username === "" && password === "") {
      setError("Email or password is not valid!");
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      ApiRequest.post("driver/sign-in", { email_id: username.toLowerCase(), password })
        .then((response) => {
          setLoading(false);
          if (!response.error) {
            StorageService.save("token", response.token);
            history.push("/check-in");
          } else {
            setError(response.error);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      loginUser();
    }
  };

  useEffect(() => {
    if (!window.navigator.onLine) {
      alert("Check your internet connection!");
    }
    if (error !== "") {
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }, [error, window.navigator.onLine]);
  return (
    <div>
      <div className="bg-black h-[60vh] bg-gradient-to-b from-[#3477A4] to-[#92D5C6] pt-[5vh]">
        <div>
          <img src={Logo} alt="nimnim" className="m-auto w-[10vh]" />
        </div>
        {error !== "" && (
          <div className="w-100 mt-4 transition delay-150 ease-in-out flex justify-between text-[1rem] font-medium text-red-500 bg-white rounded-md px-2 py-2 mx-[5vh]">
            <div>{error}</div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                onClick={() => setError("")}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
          </div>
        )}

        <div className="w-100 p-[5vh]">
          <label className="text-white text-[24px]">
            <span className="underline underline-offset-8">LO</span>GIN
          </label>
          <div className="w-100 mt-[3vh]">
            <label className="text-white text-[20px]">Email</label>
            <br />
            <input
              onChange={(e) => setUsername(e.target.value)}
              type="email"
              className="w-[100%] bg-transparent focus:outline-none border-b-2 text-white text-[20px]"
            />
          </div>
          <div className="w-100 text-white text-[20px] mt-[3vh]">
            <label className="text-white text-[20px]">Password</label>
            <br />
            <input
              onKeyPress={(e) => handleKeyPress(e)}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="w-[100%] bg-transparent focus:outline-none border-b-2 text-white text-[20px]"
            />
          </div>
        </div>
      </div>
      <div
        className="bg-gradient-to-b from-[#92D5C6] to-[#92D5C6] h-[10vh]"
        style={{ clipPath: "ellipse(50% 60% at 50% 1%)" }}
      ></div>

      <button
        onClick={() => loginUser()}
        className="mt-[10%] ml-[35%] w-[30%] bg-[#88C9BB] flex justify-center space-x-2 p-3 m-auto rounded-lg text-white"
      >
        {loading && (
          <div className=" h-6 w-6 animate-spin border-[4px] relative border-white rounded-full">
            <div className=" h-3 w-3 bg-[#88C9BB] absolute left-[50%] top-[-4px]"></div>
          </div>
        )}

        <div className=" font-medium">LOGIN</div>
      </button>
    </div>
  );
}

export default LoginScreen;
