import React, { useEffect, useState } from "react";
import directions from "../assets/img/directions.svg";
import money from "../assets/img/money.png";

import no_data_found from "../assets/img/no_data_found.svg";
import NimNimLoader from "../components/NimNimLoader";
import { Link } from "react-router-dom";
import moment from "moment";
import NavigationPopUp from "./NavigationPopUp";
import { getScheduleAction } from "../../src/redux/actions/driverAction";
import { useDispatch, useSelector } from "react-redux";
import SinglePopUP from "./SinglePopUP";
import SingleOrder from "../screen/SingleOrder";

function Location() {
  const [showPopUp, setShowPopUp] = useState(false);
  const [singlePOP, openPOPUP] = useState(false);
  const [id, setSingleId] = useState(0);
  const [orderData, setOrderData] = useState({});
  const { loading, get_schedule_responce, isCompleted } = useSelector(
    (state) => state.getScheduleReducer
  );
  const openCustomPOPUP = (id) => {
    setSingleId(id);
    openPOPUP(true);
  };
  const onpopCloses = () => {
    dispatch(getScheduleAction("", isCompleted));
    openPOPUP(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScheduleAction());
  }, []);
  return (
    <div className=" pb-5 bg-[#F9F9FF] ">
      <SinglePopUP open={singlePOP} width={"w-3/4"}>
        <div className="">
          <div className="cb-position-relative cb-position-relative-overflow">
            <div className="flex justify-between">
              <div></div>
              <div className="mt-2 text-2xl font-bold"></div>
              <span className="float:right text-2xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                  onClick={() => onpopCloses()}
                  style={{ cursor: "pointer" }}
                >
                  <path
                    fillRule="evenodd"
                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                  />
                </svg>
              </span>
            </div>
            <SingleOrder location={id} key={`key${id}`} />
          </div>
        </div>
      </SinglePopUP>
      {loading ? (
        <NimNimLoader />
      ) : (
        <div>
          {showPopUp && (
            <NavigationPopUp
              orderData={orderData}
              closeCallback={() => setShowPopUp(false)}
            />
          )}
          {get_schedule_responce.data.length > 0 ? (
            <>
              {get_schedule_responce.data.map((route, i) => {
                const {
                  orderNumber,
                  customer,
                  location,
                  deliveryPreference,
                  services,
                } = route;
                if (
                  get_schedule_responce
                    .Number_of_pickups_and_dropoffs_on_each_order[i].pickups ===
                    0 &&
                  get_schedule_responce
                    .Number_of_pickups_and_dropoffs_on_each_order[i]
                    .dropoffs === 0 &&
                  isCompleted
                ) {
                  return null;
                } else {
                  return (
                    <div
                      key={i}
                      className="bg-white mt-5 mx-6 rounded-md py-2 px-3"
                    >
                      <div
                        // to={{
                        //   pathname: `/schedule/${orderNumber}`,
                        //   state: { route },
                        // }}
                        onClick={() => openCustomPOPUP(i)}
                      >
                        <div>
                          <div className=" flex justify-between">
                            <div className=" text-[16px] text-[#000A26] cb-text-justify-rush">
                              <span className="cb-flex-grow">
                                #{orderNumber}
                              </span>
                              <div className="cb-rush-completed">
                                {services[0].need_rush_delivery ? (
                                  <span className="cb-rush text-[#41E024] flex justify-center rounded-md font-medium items-center px-2 py-1">
                                    RUSH
                                  </span>
                                ) : (
                                  <span></span>
                                )}
                                {services[0].return_preference.length > 0 &&
                                services[0].return_preference[0].is_selected ==
                                  true ? (
                                  <span className="cb-rush text-[#41E024] flex justify-center rounded-md font-medium items-center px-2 py-1">
                                    Boxed shirt
                                  </span>
                                ) : (
                                  <span></span>
                                )}
                                {get_schedule_responce
                                  .Number_of_pickups_and_dropoffs_on_each_order[
                                  i
                                ].pickups === 0 &&
                                  get_schedule_responce
                                    .Number_of_pickups_and_dropoffs_on_each_order[
                                    i
                                  ].dropoffs === 0 && (
                                    <div className=" text-[#41E024] flex justify-center rounded-md font-medium items-center px-2 py-1 bg-[#41E024]/20">
                                      COMPLETED
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          <h4 className="text-[16px] text-[#95CFC6]">
                            {deliveryPreference
                              ? deliveryPreference
                                  .toLowerCase()
                                  .includes("person")
                                ? "In Person"
                                : deliveryPreference
                                    .toLowerCase()
                                    .includes("concierge")
                                ? "Concierge"
                                : "Foyer"
                              : "Not Specified"}
                          </h4>
                          <h3 className=" text-[16px] text-[#000A26]">
                            {`${customer.first_name} ${customer.last_name}`}
                          </h3>
                          <p className="text-[14px] mt-1 text-[#000A26] opacity-40 w-9/12">
                            {Object.values(location).some((e) => e !== "")
                              ? `${location.house} ${location.street} ${location.area} ${location.city} ${location.state} ${location.pincode}`
                              : "Not specified"}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between mt-2 items-center">
                        <Link
                          key={i}
                          to={{
                            pathname: `/schedule/${orderNumber}`,
                            state: { route },
                          }}
                        >
                          <div className="text-[16px] font-medium text-[#000A26]">
                            {
                              get_schedule_responce
                                .Number_of_pickups_and_dropoffs_on_each_order[i]
                                .pickups
                            }{" "}
                            Pickups •{" "}
                            {
                              get_schedule_responce
                                .Number_of_pickups_and_dropoffs_on_each_order[i]
                                .dropoffs
                            }{" "}
                            Drops
                          </div>
                          {/* <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span style={{ color: "#3C75BE" }}>
                              Tip Earnings
                            </span>
                            <img
                              src={money}
                              alt="money"
                              style={{ height: "24px", marginTop: "2px" }}
                            />
                          </div> */}
                          
                          {/* <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span style={{ color: "#3C75BE" }}>
                              Amount:{" $"}{get_schedule_responce?.data[i]?.tip?.value}
                            </span>
                            
                          </div> */}
                        </Link>
                        <div className=" bg-[#88C9BB] cursor-pointer py-1 px-2 rounded-md">
                          <img
                            src={directions}
                            alt="directions"
                            onClick={() => {
                              setOrderData(route);
                              setShowPopUp(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </>
          ) : (
            <div className="w-100 mt-6 flex justify-center">
              <img src={no_data_found} alt="no_data_found" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Location;
