import React, { useState } from "react";
// import styled from "styled-components";
import Location from "../components/Location";
// import QrReader from 'react-qr-scanner';
import Map from "../components/Map";
import ScheduleHeader from "../components/ScheduleHeader";
// import QrCodeReader from "../components/QrCode";
// import QRCode from "react-qr-code";
// const button = styled.button``;

// const Button = styled(button)`
//   margin: 20px 0 10px;
//   padding: 15px;
//   font-weight: bold;
//   text-decoration: none;  
//   margin:5px;
//   color:#fff;
//   border:2px solid #47bfa7;
//   background: #47bfa7;
//   width:10rem;
//   border-radius: 8px;
//   &:hover{
//     background:none;
//     border:2px solid #707070;
//     color:#040404;
//   }
//   &:active {
//   color:#fff;
//   }
// `;
function Schedule() {
  const [mapLocationToggle, setMapLocationToggle] = useState("location");
  // const [data, setData] = useState('No result');
  // const handleScan = (data) => {
  //   console.log("hello");
  //   setData({
  //     data: data,
  //   })
  // }
  // const [showQR, setShowModal] = useState(false);
  // const [showCustomer, setCustomerModal] = useState(false);
  // const [value, setValue] = useState("https://google.com");
  // const [showScanner, setShowScanner] = useState(false);
  // const handleError = (err) => {
  //   console.log(err)
  // }
  // const previewStyle = {
  //   height: 240,
  //   width: 320,
  // }

  // const handleChange = (status) => {
  //   setShowModal(true);
  // }
  // const handleCancel = () => {
  //   setShowModal(false)
  // }
  // const handleClose = () => {
  //   setCustomerModal(false)
  // }
  // const childFunc = () =>{
  //   setShowScanner(true)
  // }
  return (

    <div>
      {/* <ScheduleHeader parentFunction={childFunc}/> */}
      <ScheduleHeader/>

      <div className="flex py-2 px-6 bg-white space-x-3">
        <span
          className={`${mapLocationToggle === "location"
            ? "border-b-[1.7px] text-[#000A26]"
            : "border-0 text-gray-400"
            }  border-[#000A26]  cursor-pointer text-[18px] font-medium py-1`}
          onClick={() => setMapLocationToggle("location")}
        >
          Order Queue
        </span>
        <span
          className={` ${mapLocationToggle === "map"
            ? "border-b-[1.7px] text-[#000A26]"
            : "border-0 text-gray-400"
            }  border-[#000A26] cursor-pointer  text-[18px] font-medium py-1`}
          onClick={() => setMapLocationToggle("map")}
        >
          Map
        </span>
      </div>

      {/* <p>{data}</p> */}

      {mapLocationToggle === "location" && <Location />}
      {mapLocationToggle === "map" && <Map />}
    </div>
  );
}

export default Schedule;
