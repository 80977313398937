import React from 'react'

export default function MapMarker(props) {
    return (
        <>
            <div className='w-10 h-10 bg-[#8BCEC3] rounded-3xl text-center text-white text-2xl pt-1'>
                {props.value}
            </div>
            <div style={{clipPath: 'polygon(22% 0, 44% 100%, 64% 0)'}} className='bg-[#8BCEC3] h-3 mt-[-5px] ml-[4px]'>

            </div>
        </>
    )
}
