import React from "react";
import ApiRequest from "./ApiRequest";
import FileUpload from "./FileUpload";

export default class UploadFile extends React.Component {
  state = {
    image: null,
    isUploading: false,
  };

  constructor(props) {
    super(props);
    this.state.image = this.props.initialPath;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ image: nextProps.initialPath });
  }

  upload = (data, successCallback) => { 
    this.setState({ isUploading: true });
    return ApiRequest.multipart("upload", "POST", data).then((response) => {
      this.setState({ isUploading: false });
      if (response.error || response.errors) {
      } else {
        this.setState({ image: response.path });
        if (successCallback) {
          successCallback(response);
        }
      }
    });
  };

  prepareFormData(value) {
    let formData = new FormData();
    formData.append("image", value);
    return formData;
  }

  onDrop = (acceptedFiles) => {
    this.upload(this.prepareFormData(acceptedFiles), (response) =>
      this.props.upload(response)
    );
  };

  onRemoved = () => {
    this.setState({ image: null });
    if (this.props.onRemoved) {
      this.props.onRemoved();
    }
  };

  render() {
    let imageUrl = this.state.image;

    return (
      <div className={"relative"}>
        {this.state.image ? null : <FileUpload onDrop={this.onDrop} />}
        {this.state.isUploading && <div>uploading...</div>}
        {this.state.image && (
          <div
            className=" mx-auto"
            style={{
              backgroundPosition: "center center",
              backgroundImage: `url(${imageUrl})`,
              backgroundColor: "#eeeeee",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              height: "116px",
              width: "116px",
              borderRadius: "50%",
            }}
          >
            {/* <span className="absolute bottom-0 right-[-3px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 cursor-pointer w-6 text-red-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                onClick={this.onRemoved}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span> */}
          </div>
        )}
      </div>
    );
  }
}
