import React, { createRef, useEffect, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import fileLogo from "../../src/assets/img/FileLogo.svg";
import imageCompression from "browser-image-compression";

const FileUpload = (props) => {
  const [files, setFiles] = useState([]);
 
  const {
    getRootProps,
    getInputProps,    
    isdragactive,
    isdragaccept,
    isdragreject,
  
  } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles) => {
      // console.log({ acceptedFiles });

      const imageFile = acceptedFiles[0];
      // console.log(
      //   "originalFile instanceof Blob",
      //   imageFile instanceof Blob
      // ); // true
      // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
      const options = {
        maxSizeMB: 0.001,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(imageFile, options);
        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        // console.log(compressedFile)
        if (props.onDrop) {
          console.log('image',acceptedFiles)
          props.onDrop(compressedFile);
        }
        
      } catch (error) {
        console.log(error);
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      // if (props.onDrop) {
      //   console.log('image',acceptedFiles)
      //   props.onDrop(acceptedFiles);
      // }
    },
  
  });
  
  useEffect(
    () => () => {
      
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    
    },
    [files]
  );

  return (
   
    <div {...getRootProps({ className: 'dropzone', isdragactive, isdragaccept, isdragreject })}>
      <input {...getInputProps()} accept="image/*;capture=camera" />
      <div className=" rounded-full border-[1px] border-[#eee] p-3">
        <img src={fileLogo} alt="fileLogo" />
      </div>
    </div>
  
  );
};

export default FileUpload;
