import React,{useEffect} from 'react'
import { Route, Redirect } from "react-router-dom";
import StorageService from './StorageService'

export default function PrivateRoute({ component: Component, ...rest }) {
    let token = StorageService.get('token')
    useEffect(() => {
        if (!window.navigator.onLine) {
          alert("Check your internet connection!");
        }
      }, []);
    return (
        <Route
            {...rest}
            render={props =>
                token ?
                    (Component?<Component {...props} />:rest.render(props))
                    : (
                    <Redirect
                        to={{
                            pathname: "/",
                        }}
                    />
                )
            }
        />
    );
}
