import React from "react";
import loaderLogo from "../assets/img/loaderLogo.svg";

function NimNimLoader() {
  return (
    <div className=" w-full h-[50vh] flex justify-center items-center">
      <div className="text-center">
        <img src={loaderLogo} alt="loaderLogo" />
        <h4 className=" text-[#95CFC6] text-[1rem] mt-3 ">Loading...</h4>
      </div>
    </div>
  );
}

export default NimNimLoader;
