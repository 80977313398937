function PickAndDropModal({ children, open, width }) {
  return (
    <>
      {open && (
        <div className="w-[100vw] h-[90vh] max-w-[720px] bg-black bg-opacity-20 absolute z-10 cb-pop-up">
          <div className=" w-10/12 m-auto p-3 mt-[20vh] bg-white rounded-md">
            {children}
          </div>
        </div>
      )}
    </>
  );
}

export default PickAndDropModal;
